.subscription-page {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
  margin-top: 8px;
}

.plans-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.plan-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background: white;
}

.plan-card.active {
  border: 2px solid #4CAF50;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  background-color: #e8f5e9;
}

.plan-card h2 {
  margin-top: 0;
  color: #333;
}

.plan-card .price {
  font-size: 1.5em;
  color: #555;
  font-weight: bold;
}

.plan-card ul {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.plan-card ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  color: #666;
}

.feature-controls {
  display: flex;
  align-items: center;
  gap: 5px;
}

.plan-card .feature-controls button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.plan-card .feature-controls button:hover {
  background-color: #4CAF50;
}

.plan-card .feature-controls span {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
}

.plan-actions {
  text-align: center;
  margin-top: 15px;
}

.plan-actions button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.plan-actions button:hover {
  background-color: #45a049;
}

.plan-actions button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}